// Bootstrap overrides
//
// Copy variables from `_variables.scss` to this file to override default values
// without modifying source files.

// $gray-dark:                 #cc0000 !default;

$enable-flex: true;

$navbar-light-hover-color: #f4f4f4;

$brand-primary: #000 !default;

$navbar-top-background: #000066 !default;
$navbar-bottom-background: #000066 !default;

$navbar-top-color: #fff;
$navbar-top-active-color: #fff;
$navbar-top-hover-color: #fff;

$navbar-bottom-color: #fff;
$navbar-bottom-active-color: #fff;
$navbar-bottom-hover-color: #fff;

@import '~bootstrap/scss/bootstrap.scss';

@import '../../../../vendor/z5internet/demora/src/resources/assets/scss/scss.scss';
